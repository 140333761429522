import React from "react";


import "./Browser.css"
import Slider from "./Sections/Slider/Slider";
import Info from "./Sections/Info/Info";
import ScrollBar from "../../components/ScrollBar";
import Description from "./Sections/Description/Description";
import BoxesData from "./Sections/BoxesData/BoxesData";
import Footer from "./Sections/Footer/Footer";


const Browser = (props) => {


    return (
        <div className={`container height-100`}>
            <ScrollBar>
                <Slider/>
                <Info/>
                <Description/>
                <BoxesData/>
                <Footer/>
            </ScrollBar>

        </div>

    );
};

export default Browser;
