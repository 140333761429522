import React, {useEffect, useState} from "react";
import classes from "./Traits.module.css";
import TraitsCard from "./components/TraitsCard/TraitsCard";


const Traits = (props) => {


    return (
        <div className={`container ${classes.container} column jc-start ai-center my-4`}>
            <TraitsCard data="1" name="Side 1"/>
            <TraitsCard data="2" name="Side 2"/>
            <TraitsCard data="3" name="Side 3"/>
            <TraitsCard data="4" name="Side 4"/>
            <TraitsCard data="5" name="Side 5"/>
            <TraitsCard data="6" name="Side 6"/>
            <TraitsCard data="textures" name="textures"/>
        </div>

    );
};

export default Traits;
