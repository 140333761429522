import React, {useEffect, useState} from "react";
import classes from "./Info.module.css";
import {images} from "../../../../assets/images/images";
import {getOpeanseaData} from "../../../../api/opeanseaApi/opeanseaApi";


const Info = (props) => {
    const [error, setError] = useState(false);
    const [info, setInfo] = useState({
            one_day_volume: "",
            one_day_change: "",
            one_day_sales: "",
            one_day_average_price: "",
            seven_day_volume: "",
            seven_day_change: "",
            seven_day_sales: "",
            seven_day_average_price: "",
            thirty_day_volume: "",
            thirty_day_change: "",
            thirty_day_sales: "",
            thirty_day_average_price: "",
            total_volume: "",
            total_sales: "",
            total_supply: "",
            count: "",
            num_owners: "",
            average_price: "",
            num_reports: "",
            market_cap: "",
            floor_price: ""
        }
    );

    const OpeanseaData = async () => {
        const OpeanseaDataReq = await getOpeanseaData();
        if (OpeanseaDataReq && OpeanseaDataReq.status === 200) {
            setError(false)
            setInfo(OpeanseaDataReq.data.collection.stats)

        } else {
            setError(true)
        }
    }

    useEffect(() => {
        setInfo("")
        OpeanseaData();
    }, []);



    return (
        <div className={`container ${classes.container} column jc-center ai-center`}>
            {/*<div className={`${classes.logo}`}/>*/}
            <h2 className={`font-weight-bold ${classes.title}`}>Boxes NFT</h2>

            <div className={`${classes.wrapper} my-4`}>
                <div className={`${classes.item} ${classes.borderRight}`}>
                    <span className={`text-green`}>{info.count}</span>
                    <span className={`text-whiteAlpha font-size-sm-plus`}>Items</span>
                </div>
                <div className={`${classes.item} ${classes.borderRight}`}>
                    <span className={`text-green`}>{info.num_owners}</span>
                    <span className={`text-whiteAlpha font-size-sm-plus`}>Owners</span>
                </div>
                <div className={`${classes.item} ${classes.borderRight}`}>
                    <span className={`text-green row jc-center ai-center`}><img src={images.ethereum} alt="ethereum" className={`${classes.ethereum} mr-05`}/> <span className={`ml-05`}>{info.floor_price === null ? "---" : info.floor_price}</span></span>
                    <span className={`text-whiteAlpha font-size-sm-plus`}>Floor Price</span>
                </div>
                <div className={`${classes.item} ${classes.borderRight}`}>
                    <span className={`text-green row jc-center ai-center`}><img src={images.ethereum} alt="ethereum" className={`${classes.ethereum} mr-05`}/> <span className={`ml-05`}>{info.total_volume}</span></span>
                    <span className={`text-whiteAlpha font-size-sm-plus`}>Volume Traded</span>
                </div>
                <div className={`${classes.item} cursor-pointer`} onClick={()=>window.open('https://opensea.io/collection/boxes-digital')}>
                    <img src={images.opensea} alt="OpenSea"/>
                    <span className={`text-whiteAlpha font-size-sm-plus`}>OpenSea</span>
                </div>
            </div>



        </div>

    );
};

export default Info;
