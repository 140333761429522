import React from "react";
import classes from "./Description.module.css";
import {images} from "../../../../assets/images/images";


const Description = (props) => {


    return (
        <div className={`container ${classes.container} row jc-between ai-center my-4`}>
            <div className={`pr-5`}>
                <p>A collection of 999+3 unique magic boxes, dancing at the edge of the world. Handpicked from millions of other incomparable boxes made from distinctive textures with different stamps, representing billions of boxes being shipped worldwide in the logistics industry.
                    Each box remains on the polygon network as an NFT. You can buy or sell them or give them as a gift to your loved ones. However, we suggest you keep them! Our engineers are trying to find a way to unbox them.</p>
            </div>
            <div>
                <img src={images.BoxesST} alt="BoxesST" className={``} style={{borderRadius:"8px"}}/>
            </div>
        </div>
    );
};

export default Description;
