import axios from "axios";


const OpeanseaApi = axios.create({
    baseURL: 'https://api.boxes.digital',
    responseType: "json"
});

export const getOpeanseaData = async () => {

    return await OpeanseaApi.get('/opensea')
        .then((res) => {
            return res;
        }).catch((e) => {
            if (!e.response) {
                return false;
            }
            return e.response;
        })
}
