import React, {useState} from "react";
import classes from "./BoxesData.module.css";

import Traits from "./components/Traits/Traits";
import SearchID from "./components/SearchID/SearchID";
import Items from "./components/Items/Items";


const BoxesData = (props) => {

    const [step, setStep] = useState(1);

    const stepSwitch = (step) => {
        switch (step) {
            case 1:
                return <Items />;
            case 2:
                return (
                    <Traits/>
                );
            case 3:
                return (
                    <SearchID />
                );
            default:
                return <Items />;
        }
    };


    return (
        <div className={`container ${classes.container} my-4`}>

            <div className={`my-4`}>
                <ul className={`row jc-center ai-center`}>
                    <li className={`${step === 1 ? classes.activeStep : ""}`} onClick={()=>setStep(1)}>Items</li>
                    <li className={`${step === 2 ? classes.activeStep : ""}`} onClick={()=>setStep(2)}>Traits</li>
                    <li className={`${step === 3 ? classes.activeStep : ""}`} onClick={()=>setStep(3)}>Search ID</li>
                </ul>
            </div>

            {stepSwitch(step)}



        </div>

    );
};

export default BoxesData;
