import React from "react";


import {Route, Routes} from "react-router-dom";
import Browser from "./Browser";
import {items} from "../../routes/routes";
import ShowItem from "./Sections/BoxesData/components/Items/components/ShowItem/ShowItem";


const BrowserRouter = (props) => {


    return (
        <Routes>
            <Route path="/" element={<Browser/>} />
            <Route path={items + "/:id"} element={<ShowItem />} />
        </Routes>

    );
};

export default BrowserRouter;
