import React from "react";
import classes from "./M_Traits.module.css";
import M_TraitsCard from "./components/M_TraitsCard/M_TraitsCard";


const M_Traits = (props) => {




    return (
        <div className={`container ${classes.container} column jc-start ai-center my-4`}>
            <M_TraitsCard data="1" name="Side 1"/>
            <M_TraitsCard data="2" name="Side 2"/>
            <M_TraitsCard data="3" name="Side 3"/>
            <M_TraitsCard data="4" name="Side 4"/>
            <M_TraitsCard data="5" name="Side 5"/>
            <M_TraitsCard data="6" name="Side 6"/>
            <M_TraitsCard data="textures" name="textures"/>
        </div>

    );
};

export default M_Traits;
