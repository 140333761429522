import React, {useEffect, useState} from "react";
import classes from "./TraitsCard.module.css";
import {images} from "../../../../../../../../assets/images/images";
import {getTraitsData} from "../../../../../../../../api/opeanseaApi/traitsApi";
import {items} from "../../../../../../../../routes/routes";
import Icon from "../../../../../../../../components/Icon/Icon";


const TraitsCard = (props) => {
    const {data, name} = props

    const [isOpen, setIsOpen] = useState(false);
    const [subContent, setSubContent] = useState(false);


    const Baseurl = 'https://boxes.digital/';


    const [error, setError] = useState(false);
    const [traitsData, setTraitsData] = useState([]);

    const TraitsData = async (traits) => {
        const TraitsDataReq = await getTraitsData(traits);
        if (TraitsDataReq && TraitsDataReq.status === 200) {
            setError(false)
            setTraitsData(TraitsDataReq.data)
            setSubContent(true)

        } else {
            setError(true)
        }
    }


    const selectAppHandler = () => {
        setIsOpen(prevState => !prevState)
        if (!subContent) {
            TraitsData(data)
        }
    }


    useEffect(() => {

    }, [isOpen]);

    return (
        /*data.data.map((item, index) =>*/
        <div className={`container ${classes.container} column jc-center ai-center my-2 font-size-sm-plus`}>
            <div
                className={`container ${classes.content} row jc-center ai-center px-2 cursor-pointer  ${isOpen ? classes.cardWBorder : classes.cardBorder}`}
                onClick={selectAppHandler}>
                <div className={`${classes.name} row jc-start ai-center`}>
                    <Icon iconName="icon-list-round-bullet font-size-sm-plus flex" iconClass={`px-05`}/>
                    <span className={`ml-05`}>{name}</span>
                </div>
                <div className={`${classes.arrow} flex jc-center ai-center`}>
                    <Icon iconName={`${isOpen ? "icon-up" : "icon-down"} font-size-sm-plus flex`} iconClass={`px-05`}/>
                </div>
            </div>
            <div className={`container ${classes.subContent}`} style={{display: isOpen ? "revert" : "none",}}>
                <div className={`container row jc-around ai-center py-2 ${classes.title}`}>
                    <span className={`col-25 flex jc-center ai-center`}>Icon</span>
                    <span className={`col-25 flex jc-center ai-center`}>Name</span>
                    <span className={`col-25 flex jc-center ai-center`}>Count</span>
                    <span className={`col-25 flex jc-center ai-center`}>Rarity Point</span>
                </div>
                {subContent ?
                    traitsData.map((item, index) =>
                        <div className={`container row mb-05 ${classes.subContentRow}`}>
                            {/*style={{backgroundImage: `url("${Baseurl + item.url}")`}}*/}
                            <div className={`${classes.icon} col-25 flex jc-center ai-center`}>
                                <img src={`${Baseurl + item.url}`} alt={item.name}/>
                            </div>
                            <div className={`col-25 flex jc-center ai-center`}>
                                <span>{item.name}</span>
                            </div>
                            <div className={`col-25 flex jc-center ai-center`}>
                                <span>{item.count}</span>

                            </div>
                            <div className={`col-25 flex jc-center ai-center`}>
                                <span>{(item.rarity * 100).toFixed(2)} %</span>

                            </div>
                            {/*<div className={`${classes.body} row jc-between ai-center px-3`}>

                            </div>*/}
                        </div>
                    )

                     : <div className={`container flex jc-center ai-center`} style={{height: "10vh"}}>Loading</div>}

            </div>
        </div>
        /*)*/
    );
};

export default TraitsCard;
