import React from "react";
import classes from "./Footer.module.css";

const Footer = (props) => {


    return (
        <div className={`container ${classes.container} flex jc-center ai-center`}>
            <p>Designed and developed by <span className={`cursor-pointer`} onClick={()=>window.open('https://titec-co.ir/')}>TiTec</span> and <span className={`cursor-pointer`}  onClick={()=>window.open('https://nilin.co/')}>Nilin</span></p>
        </div>

    );
};

export default Footer;
