import React from "react";
import classes from "./ItemsNavbar.module.css";
import Icon from "../../../../../../../../components/Icon/Icon";


const ItemsNavbar = (props) => {

    const {data , setURL} = props;
    const ipfsURL = 'https://ipfs.io/ipfs/';


    return (
           <div className={`${classes.container} container row jc-center ai-center px-2 py-2`}>
               <span className={`pr-1 cursor-pointer hover`} onClick={() => setURL(0)}>First page</span>
               <Icon iconName=" icon-left font-size-md flex" iconClass={`px-05 cursor-pointer hover`} title={`page ${data.current}`} onClick={() => setURL(data.previous)}/>
               {/*<img src={images.left} alt="left" onClick={() => setURL(data.previous)}/>*/}
               <span className={`px-2 py-1 hover ${classes.currentPage}`}>page {data.current +1}</span>
               {/*<img src={images.right} alt="left" onClick={() => setURL(data.next)}/>*/}
               <Icon iconName=" icon-right font-size-md flex" iconClass={`px-05 cursor-pointer hover`} title={`page ${data.current + 2}`} onClick={() => setURL(data.next)}/>

               <span className={`pl-1 cursor-pointer hover`} onClick={() => setURL(data.lastPage - 1)}>Last page</span>





           </div>
    );

};

export default ItemsNavbar;
