import React from "react";
import classes from "./M_Slider.module.css";
import {images} from "../../../../assets/images/images";


const M_Slider = (props) => {


    return (
        <div className={`container ${classes.container}`} style={{backgroundImage: `url("${images.M_BoxesSlider}")`}}>
            {/*<img src={images.BoxesSlider} alt="BoxesSlider"/>*/}
            <div className={`row ${classes.link}`}>
                <span className={`pr-1 cursor-pointer ${classes.hover}`} onClick={()=>window.open('https://gift.boxes.digital/')}>Gift </span><span> | </span><span className={`pl-1 cursor-pointer ${classes.hover}`} onClick={()=>window.open('https://help.boxes.digital/')}> Help</span>
            </div>

        </div>

    );
};

export default M_Slider;
