import React from "react";




import {Route, Routes} from "react-router-dom";
import {items} from "../../routes/routes";
import Mobile from "./Mobile";
import M_ShowItem from "./Sections/M_BoxesData/components/M_Items/components/M_ShowItem/M_ShowItem";


const MobileRouter = (props) => {


    return (
        <Routes>
            <Route path="/" element={<Mobile/>} />
            <Route path={items + "/:id"} element={<M_ShowItem />} />
        </Routes>

    );
};

export default MobileRouter;
