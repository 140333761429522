import axios from "axios";


const BoxApi = axios.create({
    baseURL: 'https://api.boxes.digital',
    responseType: "json"
});

export const getBoxData = async (id) => {

    return await BoxApi.get(`/boxes/${id}`)
        .then((res) => {
            return res;
        }).catch((e) => {
            if (!e.response) {
                return false;
            }
            return e.response;
        })
};