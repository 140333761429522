import React, {useState} from "react";
import classes from "./ItemsCard.module.css";
import ReactPlayer from 'react-player/lazy'
import {Link} from "react-router-dom";


const ItemsCard = (props) => {

    const {data, thisClass} = props;
    const ipfsURL = 'https://ipfs.io/ipfs/';
    const baseURL = 'https://boxes.digital/frame';

    const [mouseenter, setMouseenter] = useState(false);

    const MouseEnterEventHandler = ()=> {
        setMouseenter(true)
    }
    const MouseLeaveEventHandler = ()=> {

        setMouseenter(false)
    }

    return (
        <div className={`container row jc-center ai-center ${classes.container} mt-2`}>
            {data.map((item, index) => <div className={` ${classes.itemBox} column jc-center ai-center ${thisClass ?? ""}`} onMouseEnter={()=>MouseEnterEventHandler()} onMouseLeave={()=>MouseLeaveEventHandler()}>
                <Link to={"/items/" + item.id} target="_blank" className={`${classes.content} container`} key={index}>
                    {/*<ReactPlayer
                        url={`${ipfsURL + item.ipfsUrl}`}
                        playing={mouseenter}
                        loop={true}
                        volume={1}
                        //muted={false}
                        controls={true}
                        width="100%"
                        height="100%"
                    />*/}
                    <img src={`${baseURL + "/" + item.id + ".png"}`} alt=""/>
                </Link>

                <div className={`${classes.footer} container column jc-center ai-center px-2 py-1`}>
                    <span style={{color: "#29bb23"}}>Box #{item.id}</span>
                    <span>Rarity score: <span>{item.rarity}</span></span>
                </div>
            </div>)}
        </div>
    );
};

export default ItemsCard;
