import React, {useState} from "react";
import classes from "./M_BoxesData.module.css";
import M_Items from "./components/M_Items/M_Items";
import M_Traits from "./components/M_Traits/M_Traits";
import M_SearchID from "./components/M_SearchID/M_SearchID";



const M_BoxesData = (props) => {

    const [step, setStep] = useState(1);

    const stepSwitch = (step) => {
        switch (step) {
            case 1:
                return <M_Items />;
            case 2:
                return (
                    <M_Traits />
                );
            case 3:
                return (
                    <M_SearchID />
                );
            default:
                return <M_Items />;
        }
    };


    return (
        <div className={`container ${classes.container} my-4`}>

            <div className={`my-4`}>
                <ul className={`row jc-center ai-center`}>
                    <li className={`${step === 1 ? classes.activeStep : ""}`} onClick={()=>setStep(1)}>Items</li>
                    <li className={`${step === 2 ? classes.activeStep : ""}`} onClick={()=>setStep(2)}>Traits</li>
                    <li className={`${step === 3 ? classes.activeStep : ""}`} onClick={()=>setStep(3)}>Search ID</li>
                </ul>
            </div>

            {stepSwitch(step)}



        </div>

    );
};

export default M_BoxesData;
