import BoxesSlider from "./src/BoxesSlider.gif";
import M_BoxesSlider from "./src/M_BoxesSlider.gif";
import BoxesST from "./src/BoxesST.gif";
import opensea from "./src/opensea.svg";
import menu_squared from "./src/menu_squared.svg";
import list from "./src/list-round-bullet.svg";
import down from "./src/down.svg";
import up from "./src/up.svg";
import search from "./src/search.svg";
import right from "./src/right.svg";
import left from "./src/left.svg";
import ethereum from "./src/ethereum.svg";



export const images = {
    BoxesSlider: BoxesSlider,
    M_BoxesSlider: M_BoxesSlider,
    BoxesST: BoxesST,
    opensea: opensea,
    menu_squared: menu_squared,
    list: list,
    down: down,
    up: up,
    search: search,
    right: right,
    left: left,
    ethereum: ethereum,
};
