import React from "react";
import {isBrowser, isMobile, isTablet} from "react-device-detect";
import MobileRouter from "./Mobile/MobileRouter";
import BrowserRouter from "./Browser/BrowserRouter";


const Main = (props) => {

    let mainContent = <div/>

    if (isMobile) {
        mainContent = <MobileRouter/>
    }
    if (isTablet) {
        mainContent = <MobileRouter/>
    }
    if (isBrowser) {
        mainContent = <BrowserRouter/>
    }


        return (
        /*basename={"home"}*/
        /*"homepage":"https://boxes.digital"*/
        <>
            {mainContent}
        </>

    );
};

export default Main;
