import React, {useEffect, useState} from "react";
import classes from "./M_Items.module.css";
import {images} from "../../../../../../assets/images/images";
import M_ItemsCard from "./components/M_ItemsCard/M_ItemsCard";
import {getBoxesData} from "../../../../../../api/opeanseaApi/boxesApi";
import ItemsNavbar from "../../../../../Browser/Sections/BoxesData/components/Items/components/ItemsNavbar/ItemsNavbar";
import ItemsCard from "../../../../../Browser/Sections/BoxesData/components/Items/components/ItemsCard/ItemsCard";
import M_ItemsNavbar from "./components/M_ItemsNavbar/M_ItemsNavbar";


const M_Items = (props) => {
    const [error, setError] = useState(false);
    const [page, setPage] = useState(0);
    const [numPerPage, setNumPerPage] = useState(5);
    const [itemsData, setItemsData] = useState({
        pagination: {},
        results: [],
    });
    const BoxesData = async (p, npp) => {
        const BoxesDataReq = await getBoxesData(p, npp);
        if (BoxesDataReq && BoxesDataReq.status === 200) {
            setError(false)
            setItemsData(BoxesDataReq.data)

        } else {
            setError(true)
        }
    }
    useEffect(() => {
        BoxesData(page , numPerPage);
    }, []);
    useEffect(() => {
        BoxesData(page , numPerPage);
    }, [page]);
    const errorSection = <div className={`container flex jc-center ai-center`} style={{height: "50vh"}}>
        <span>Something went wrong :(</span>
    </div>


    return (
        <div className={`${classes.container} row`}>

            {error ? errorSection :
                <>
                    <M_ItemsNavbar data={itemsData.pagination} setURL={(newPage)=>setPage(newPage)}/>
                    <M_ItemsCard data={itemsData.results}/>
                </>
            }

        </div>

    );
};

export default M_Items;
