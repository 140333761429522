import React, {useEffect, useState} from "react";
import classes from "./ShowItem.module.css";
import {Link, useParams} from "react-router-dom";
import {getBoxData} from "../../../../../../../../api/opeanseaApi/boxApi";
import ReactPlayer from "react-player/lazy";
import Icon from "../../../../../../../../components/Icon/Icon";


const ShowItem = (props) => {

    const Baseurl = 'https://boxes.digital/';
    /*const IpfsURL = 'https://ipfs.io/ipfs/';*/

    const OpeanseaURL = 'https://opensea.io/assets/matic/0xf8b17383415834a7c10e109282d5f0554ea1ba55/';
    const { id } = useParams()

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [itemData, setItemData] = useState([]);
    const [mouseenter, setMouseenter] = useState(false);


    const BoxData = async (id) => {
        const BoxDataReq = await getBoxData(id);
        if (BoxDataReq && BoxDataReq.status === 200) {
            setError(false)
            setItemData(BoxDataReq.data)
            setLoading(false)
        } else {
            setError(true)
        }
    }

    useEffect(() => {
        BoxData(id);
    }, [id]);



    const MouseEnterEventHandler = () => {
        setMouseenter(true)
    }
    const MouseLeaveEventHandler = () => {
        setMouseenter(false)
    }
    if (loading) return <div className={`${classes.loading} flex jc-center ai-center`}>Loading...</div>



    return (
        <div className={`container col-100 ${classes.container} row jc-between ai-center`}>


            <Link to={"/"}className={` ${classes.back}`}>
                <Icon iconName="icon-left flex" iconClass={`px-05 mt-1 cursor-pointer hover`} title={`Back to home`}/>
            </Link>



            <div className={`col-50 height-100 column jc-center ai-center`}>
                <div className={`row jc-center ai-center my-2`}>

                    <span className={`font-size-md mx-1`} style={{color: "#29bb23"}}>Box #{itemData[0].id}</span>

                </div>                <div className={`mb-2 ${classes.videoFrame}`}>
                    <ReactPlayer
                        onMouseEnter={() => MouseEnterEventHandler()}
                        onMouseLeave={() => MouseLeaveEventHandler()}
                        //url={`${IpfsURL + itemData[0].ipfsUrl}`}
                        url={`https://${itemData[0].ipfsUrl}.ipfs.dweb.link/`}
                        playing={mouseenter}
                        loop={true}
                        volume={1}
                        //muted={false}
                        controls={true}
                        width="100%"
                        height="100%"
                    />
                </div>
                <div className={`row jc-center ai-center mt-2`}>

                    <span className={`mx-1`}>Rarity score: <span>{itemData[0].rarity}</span></span>
                    <Icon iconName="icon-open-external-link flex" iconClass={`mx-1 cursor-pointer hover`} title={`Box #${itemData[0].id} in opeansea`} onClick={()=>window.open(`${OpeanseaURL + itemData[0].id}`)}/>

                </div>


            </div>
            <div className={`col-50`}>
                <div className={`col-100 my-4 row jc-center ai-center`}>
                    <div className={`col-10 column jc-center ai-center`}>
                        <img src={`${Baseurl + itemData[0].texture}`} alt=""/>
                        <span className={`mt-2`}>Texture</span>
                    </div>
                </div>
                <div className={`col-100  my-4 row jc-center ai-center`}>
                    <div className={`col-10 column jc-center ai-center mx-1`}>
                        <img src={`${Baseurl + itemData[0].side1}`} alt=""/>
                        <span className={`mt-2`}>South</span>
                    </div>
                    <div className={`col-10 column jc-center ai-center mx-1`}>
                        <img src={`${Baseurl + itemData[0].side2}`} alt=""/>
                        <span className={`mt-2`}>East</span>
                    </div>
                    <div className={`col-10 column jc-center ai-center mx-1`}>
                        <img src={`${Baseurl + itemData[0].side3}`} alt=""/>
                        <span className={`mt-2`}>Top</span>
                    </div>
                </div>
                <div className={`col-100  my-4 row jc-center ai-center`}>
                    <div className={`col-10 column jc-center ai-center mx-1`}>
                        <img src={`${Baseurl + itemData[0].side4}`} alt=""/>
                        <span className={`mt-2`}>North</span>
                    </div>
                    <div className={`col-10 column jc-center ai-center mx-1`}>
                        <img src={`${Baseurl + itemData[0].side5}`} alt=""/>
                        <span className={`mt-2`}>West</span>
                    </div>
                    <div className={`col-10 column jc-center ai-center mx-1`}>
                        <img src={`${Baseurl + itemData[0].side6}`} alt=""/>
                        <span className={`mt-2`}>Down</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShowItem;
