import React, {Fragment, useState} from "react";
import classes from "./SearchID.module.css";
import {images} from "../../../../../../assets/images/images";
import ReactPlayer from "react-player/lazy";
import {getBoxData} from "../../../../../../api/opeanseaApi/boxApi";
import Icon from "../../../../../../components/Icon/Icon";


const SearchID = (props) => {

    const [boxFrame, setBoxFrame] = useState(false);

    const [search, setSearch] = useState("");

    const Baseurl = 'https://boxes.digital/';
    const OpeanseaURL = 'https://opensea.io/assets/matic/0xf8b17383415834a7c10e109282d5f0554ea1ba55/';



    const ipfsURL = 'https://ipfs.io/ipfs/';

    const [error, setError] = useState(false);
    const [alert, setAlert] = useState("");
    const [searchData, setSearchData] = useState([]);

    const BoxData = async (search) => {
        const BoxDataReq = await getBoxData(search);
        if (BoxDataReq && BoxDataReq.status === 200) {
            setError(false)
            setSearchData(BoxDataReq.data)
            setBoxFrame(true)

        } else {
            setError(true)
        }
    }


    const submit = async (e) => {
        setAlert(false)
        e.preventDefault();
        if (search <= 0 || search > 1002) {
            setAlert("ID must be between or equal to 1 to 1002")
        } else {

            await BoxData(search)
        }


    }

    const [mouseenter, setMouseenter] = useState(false);

    const MouseEnterEventHandler = () => {
        setMouseenter(true)
    }
    const MouseLeaveEventHandler = () => {
        setMouseenter(false)
    }

    return (
        <Fragment>
            <form onSubmit={(e) => submit(e)}
                  className={`container ${classes.container} ${boxFrame ? classes.result : ""} column jc-center ai-center`}>
                <div className={`${classes.inputGroup} column`}>
                    <div className={`${classes.input} row`}>
                        <input
                            type="number"
                            placeholder="Box ID"
                            //min="1" max="999"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <button
                            className={`flex jc-center ai-center cursor-pointer`}
                        ><img src={images.search} alt="search"/>
                        </button>
                    </div>
                    <div className={`${classes.alert} flex jc-start mt-05`}>
                        {alert !== "" ? <span>{alert}</span> : ""}

                    </div>
                </div>
            </form>

            {boxFrame ?
                <div className={`${classes.frame} container column`}>

                    <div className={`col-100 row`}>

                        <div className={`col-50 flex jc-center ai-center mb-2`}>
                            {/*<ItemsCard data={data} thisClass={`${classes.thisClass}`}/>*/}
                            <div className={``} style={{width: "70%" , height: "100%"}}>
                                <ReactPlayer
                                    onMouseEnter={() => MouseEnterEventHandler()}
                                    onMouseLeave={() => MouseLeaveEventHandler()}
                                    /*url={`${ipfsURL + searchData[0].ipfsUrl}`}*/
                                    url={`https://${searchData[0].ipfsUrl}.ipfs.dweb.link/`}
                                    playing={mouseenter}
                                    loop={true}
                                    volume={1}
                                    //muted={false}
                                    controls={true}
                                    width="100%"
                                    height="100%"
                                />
                            </div>

                        </div>
                        <div className={`col-50 column jc-center ai-center`}>
                            <span className={`font-size-md`} style={{color: "#29bb23"}}>Box #{searchData[0].id}</span>
                            <span className={`my-2`}>Rarity score: <span>{searchData[0].rarity}</span></span>
                            <Icon iconName="icon-open-external-link font-size-md flex" iconClass={`px-05 mt-1 cursor-pointer hover`} title={`Box #${searchData[0].id} in opeansea`}  onClick={()=>window.open(`${OpeanseaURL + searchData[0].id}`)}/>


                        </div>
                    </div>
                    <div className={`col-100 my-4 row jc-center ai-center`}>
                        <div className={`col-10 column jc-center ai-center`}>
                            <img src={`${Baseurl + searchData[0].texture}`} alt=""/>
                            <span className={`mt-2`}>Texture</span>
                        </div>
                    </div>
                    <div className={`col-100  my-4 row jc-center ai-center`}>
                        <div className={`col-10 column jc-center ai-center mx-1`}>
                            <img src={`${Baseurl + searchData[0].side1}`} alt=""/>
                            <span className={`mt-2`}>South</span>
                        </div>
                        <div className={`col-10 column jc-center ai-center mx-1`}>
                            <img src={`${Baseurl + searchData[0].side2}`} alt=""/>
                            <span className={`mt-2`}>East</span>
                        </div>
                        <div className={`col-10 column jc-center ai-center mx-1`}>
                            <img src={`${Baseurl + searchData[0].side3}`} alt=""/>
                            <span className={`mt-2`}>Top</span>
                        </div>
                        <div className={`col-10 column jc-center ai-center mx-1`}>
                            <img src={`${Baseurl + searchData[0].side4}`} alt=""/>
                            <span className={`mt-2`}>North</span>
                        </div>
                        <div className={`col-10 column jc-center ai-center mx-1`}>
                            <img src={`${Baseurl + searchData[0].side5}`} alt=""/>
                            <span className={`mt-2`}>West</span>
                        </div>
                        <div className={`col-10 column jc-center ai-center mx-1`}>
                            <img src={`${Baseurl + searchData[0].side6}`} alt=""/>
                            <span className={`mt-2`}>Down</span>
                        </div>
                    </div>
                </div>
                : ""}

        </Fragment>

    );
};

export default SearchID;
