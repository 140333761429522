import axios from "axios";


const TraitsApi = axios.create({
    baseURL: 'https://api.boxes.digital',
    responseType: "json"
});

export const getTraitsData = async (traits) => {

    return await TraitsApi.get(`traits/${traits}`)
        .then((res) => {
            return res;
        }).catch((e) => {
            if (!e.response) {
                return false;
            }
            return e.response;
        })
};