import React from "react";


import "./Mobile.css"
import ScrollBar from "../../components/ScrollBar";
import M_Slider from "./Sections/M_Slider/M_Slider";
import M_Info from "./Sections/M_Info/M_Info";
import M_Description from "./Sections/M_Description/M_Description";
import M_BoxesData from "./Sections/M_BoxesData/M_BoxesData";
import Footer from "../Browser/Sections/Footer/Footer";


const Mobile = (props) => {


    return (
        <div>
            <ScrollBar>
                <M_Slider/>
                <M_Info/>
                <M_Description/>
                <M_BoxesData/>
                <Footer/>
            </ScrollBar>
        </div>

    );
};

export default Mobile;
