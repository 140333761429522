import axios from "axios";


const BoxesApi = axios.create({
    baseURL: 'https://api.boxes.digital',
    responseType: "json"
});

export const getBoxesData = async (page=0 , numPerPage=6) => {

    return await BoxesApi.get(`/boxes?page=${page}&numPerPage=${numPerPage}`)
        .then((res) => {
            return res;
        }).catch((e) => {
            if (!e.response) {
                return false;
            }
            return e.response;
        })
};